<template>
  <div>
    <notifications group="app" />
    <Spinner v-show="isLoading" />
    <slot />
  </div>
</template>
<script>
  import Spinner from '@/components/base/spinner';
  import { mapState } from 'vuex';
  export default {
    name: 'PublicLayout',
    components: {
      Spinner
    },
    computed: {
      ...mapState(['isLoading'])
    }
  };
</script>
