<template>
  <div class="nav-menu">
    <ul class="nav-menu-list" data-test="nav-menu">
      <li>
        <router-link class="nav-menu-link" :to="{ name: 'config-clinicians' }">
          Clinicians
        </router-link>
      </li>

      <li>
        <router-link class="nav-menu-link" :to="{ name: 'config-clinician-orders' }">
          Clinician Order
        </router-link>
      </li>

      <li>
        <router-link class="nav-menu-link" :to="{ name: 'config-filters' }">
          Filters
        </router-link>
      </li>

      <li>
        <router-link class="nav-menu-link" :to="{ name: 'config-specialties' }">
          Specialties
        </router-link>
      </li>

      <li>
        <router-link class="nav-menu-link" :to="{ name: 'config-languages' }">
          Languages
        </router-link>
      </li>

      <li>
        <router-link class="nav-menu-link" :to="{ name: 'config-schools' }">
          Schools
        </router-link>
      </li>

      <li>
        <router-link class="nav-menu-link" :to="{ name: 'config-generic-testimonials' }">
          Generic Testimonials
        </router-link>
      </li>

      <li>
        <router-link class="nav-menu-link" :to="{ name: 'config-insurance-payors' }">
          Insurance Payors
        </router-link>
      </li>

      <li>
        <router-link class="nav-menu-link" :to="{ name: 'config-clinician-types' }">
          Clinician Types
        </router-link>
      </li>

      <li>
        <router-link class="nav-menu-link" :to="{ name: 'config-departments' }">
          Departments
        </router-link>
      </li>

      <li>
        <router-link class="nav-menu-link" :to="{ name: 'config-cancellation-reasons' }">
          Cancellation Reasons
        </router-link>
      </li>

      <li>
        <router-link class="nav-menu-link" :to="{ name: 'config-bookings' }">
          Booking Window
        </router-link>
      </li>

      <li>
        <router-link class="nav-menu-link" :to="{ name: 'config-appointments' }">
          Appointment Types
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'Sidebar',
    data() {
      return {};
    }
  };
</script>

<style scoped>
  .nav-menu {
    position: relative;
    z-index: 10;
    width: 192px;
    position: relative;
    background-color: #e2e8f0;
  }

  .nav-menu-list {
    text-align: left;
    padding: 12px 0px;
    display: flex;
    flex-direction: column;
  }

  .nav-menu-list li {
    flex: 1 1 0%;
  }

  .nav-menu-link {
    padding: 0px 12px;
    display: flex;
    height: 50px;
    align-items: center;
  }

  .nav-menu-link:hover {
    color: white;
  }

  .router-link-active {
    background-color: #cbd5e0;
  }
</style>
