import Vuex from 'vuex';
import Vue from 'vue';
import modules from './modules';

Vue.use(Vuex);

const state = () => ({
  isGlobalLoader: false,
  isLoading: false,
  isHeaderTop: true,
  publicRoutes: ['index', 'login', 'no-found'],
  request: {
    retries: [],
    isGroup: false
  }
});

const mutations = {
  setGlobalLoader(state, payload) {
    state.isGlobalLoader = payload;
    state.isLoading = payload;
    state.request.isGroup = payload;
  },
  setLoader(state, payload) {
    state.isLoading = payload;
  },
  setHeader(state, payload) {
    state.isHeaderTop = payload;
  },
  setRequestGroup(state, payload) {
    if (state.isGlobalLoader) return;
    state.request.isGroup = payload;
  },
  createRetryRequest(state, url) {
    state.request.retries.push({ url, count: 0 });
  },
  deleteRetryRequest(state, url) {
    state.request.retries = state.request.retries.filter((request) => request.url !== url);
  },
  addRetryRequestCount(state, url) {
    state.request.retries.find((request) => request.url == url).count++;
  }
};

const actions = {
  setLoader({ commit, state }, payload) {
    if (state.request.isGroup || state.isGlobalLoader) return;
    commit('setLoader', payload);
  },
  setGlobalLoader({ commit }, payload) {
    commit('setGlobalLoader', payload);
  },
  setRequestGroup({ commit }, payload) {
    if (state.isGlobalLoader) return;
    commit('setRequestGroup', payload);
  },
  moveHeader({ commit }) {
    commit('setHeader', false);
  },
  restoreHeader({ commit }) {
    commit('setHeader', true);
  }
};

const STORE = new Vuex.Store({
  state,
  mutations,
  actions,
  modules
});

export default STORE;
