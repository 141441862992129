import { handleError, immutableUpdateListItems, sort } from '@/utils';
import Axios from '@/libs/axios';
import insurancePlans from './insurancePlans';

const DEFAULT_VALUE = {
  name: '',
  enabled: true,
  cardImages: [],
  insurancePlans: []
};

const state = {
  currentId: null,
  list: []
};

const actions = {
  async getList({ commit, getters, dispatch }, override) {
    if (getters.hasList && !override) return;
    dispatch('setLoader', true, { root: true });

    try {
      await dispatch('departments/getProviderGroupData', null, { root: true });
      const INSURANCE_PAYORS = await Axios.get('/insurancepayors');
      commit('setList', INSURANCE_PAYORS.data);
      await dispatch('states/getList', null, { root: true });
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async create({ commit, dispatch }, payload) {
    dispatch('setLoader', true, { root: true });

    try {
      const {
        data: { id }
      } = await Axios.post(`/insurancepayors`, payload);
      commit('add', { ...DEFAULT_VALUE, ...payload, id });
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
      setTimeout(() => {
        dispatch('getList', true);
      }, 4000);
    }
  },
  async update({ commit, dispatch }, payload) {
    const { id, data } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch(`/insurancepayors/${id}`, data);
      commit('updateListItem', payload);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  resetCurrentId({ commit }) {
    commit('setCurrentId', null);
  },
  setCurrentId({ commit }, payload) {
    commit('setCurrentId', payload);
  },
  updateListItem({ commit }, payload) {
    commit('updateListItem', payload);
  }
};

const mutations = {
  updateListItem(state, payload) {
    state.list = immutableUpdateListItems(state.list, payload);
  },
  add(state, data) {
    state.list = [...state.list, data];
  },
  setList(state, payload) {
    state.list = payload;
  },
  setCurrentId(state, payload) {
    state.currentId = payload;
  }
};

const getters = {
  hasList: (state) => {
    return state.list.length > 0;
  },
  list: (state) => {
    return sort(state.list, 'name');
  }
};
export default {
  namespaced: true,
  modules: {
    insurancePlans
  },
  state,
  actions,
  mutations,
  getters
};
