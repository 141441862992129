<template>
  <portal to="destination" :disabled="isHeaderTop">
    <nav class="navbar">
      <div class="navbar-logo-container">
        <img class="navbar-logo" src="@/assets/img/hardloop_logo.png" alt="Hardloop Logo" />
        <span class="navbar-title">{{ email }}</span>
      </div>

      <div class="navbar-buttons-container">
        <button class="signout-btn" @click="signout()">
          Sign Out
        </button>
      </div>
    </nav>
  </portal>
</template>

<script>
  import { mapState, mapActions, mapGetters } from 'vuex';
  export default {
    name: 'Header',
    computed: {
      ...mapState(['isLoading', 'isHeaderTop']),
      ...mapGetters('user', ['email'])
    },
    methods: {
      ...mapActions('user', ['logOut']),
      signout() {
        this.$modal.hideAll();
        return this.logOut()
          .then(() => {
            this.$router.push('/');
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };
</script>

<style scoped>
  .navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 8px 20px;
    background-color: #507297;
  }

  .navbar-title {
    letter-spacing: -0.025em;
    font-size: 20px;
    font-weight: 600;
    margin: 0px 10px;
  }

  .navbar-logo {
    width: 64px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 100px;
  }

  .navbar-logo-container {
    display: flex;
    color: #fff;
    margin-right: 25px;
    flex-shrink: 0;
    align-items: center;
  }

  .navbar-buttons-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  .signout-btn {
    color: #fff;
    padding: 8px 14px;
    line-height: 1;
    font-size: 14px;
    border: 1px solid #fff;
    border-radius: 4px;
  }
  .signout-btn:hover {
    color: #38b2ac;
    border: 0px;
    background-color: #fff;
  }
</style>
