import { handleError, sort } from '@/utils';

import Axios from '@/libs/axios';

const state = {
  list: []
};

const actions = {
  async getList({ commit, getters, dispatch }) {
    if (getters.hasList) return;
    dispatch('setLoader', true, { root: true });

    try {
      const RECORDS = await Axios.get('/titles');
      commit('setList', RECORDS.data);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  }
};

const mutations = {
  setList(state, payload) {
    state.list = payload;
  }
};

const getters = {
  hasList: (state) => {
    return state.list.length > 0;
  },
  list: (state) => {
    return sort(state.list, 'name');
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
