import genericTestimonials from './genericTestimonials';
import appointmentTypes from './appointmentTypes';
import insurancePayors from './insurancePayors';
import clinicianTypes from './clinicianTypes';
import cancellationReasons from './cancellationReasons';
import testimonials from './testimonials';
import specialties from './specialties';
import departments from './departments';
import clinicians from './clinicians';
import languages from './languages';
import education from './education';
import suffixes from './suffixes';
import settings from './settings';
import schools from './schools';
import titles from './titles';
import states from './states';
import user from './user';

export default {
  genericTestimonials,
  appointmentTypes,
  insurancePayors,
  clinicianTypes,
  cancellationReasons,
  testimonials,
  departments,
  specialties,
  clinicians,
  languages,
  education,
  settings,
  suffixes,
  schools,
  states,
  titles,
  user
};
