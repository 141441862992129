import { immutableUpdateListItems, handleError, sort } from '@/utils';
import Axios from '@/libs/axios';

const state = {
  list: []
};

const actions = {
  async getList({ commit, getters, dispatch }) {
    if (getters.hasList) return;
    dispatch('setLoader', true, { root: true });

    try {
      const STATES = await Axios.get('/states');
      commit('setList', STATES.data);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async update({ commit, dispatch }, payload) {
    const { id, data } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch(`/states/${id}`, data);
      commit('updateListItem', payload);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateDepartment({ commit, dispatch }, payload) {
    const { id, data } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch(`/states/${id}`, data);
      commit('updateListItem', payload);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  }
};

const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
  updateListItem(state, payload) {
    state.list = immutableUpdateListItems(state.list, payload);
  }
};

const getters = {
  hasList: (state) => {
    return state.list.length > 0;
  },
  inNetworkStates: (state, getters, rootState, rootGetters) => {
    if (!getters.hasList || !rootGetters['insurancePayors/hasList']) return [];
    const STATES = [];
    // Loop through all the insurancePayors and return only those states that found at least one plan with the state inNetwork true
    for (const payor of rootState.insurancePayors.list) {
      if (payor.insurancePlans.length === 0) continue;
      if (!payor.enabled) continue;
      for (const plan of payor.insurancePlans) {
        if (plan === null || plan.states.length === 0) continue;

        for (const state of plan.states) {
          if (!state.inNetwork) continue;
          if (STATES.some((record) => record.stateName == state.stateName)) continue;

          STATES.push(state);
        }
      }
    }
    return sort(STATES, 'stateName');
  },
  list: (state) => {
    return sort(state.list, 'stateName');
  },
  schedulerFilterEnabledList: (state, getters) => {
    return getters.list.filter((record) => record.schedulerFilterEnabled);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
