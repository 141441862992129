import { getRecord, handleError, immutableUpdateListItems } from '@/utils';
import Axios from '@/libs/axios';

function _add(payload) {
  const { list, id, plan } = payload;
  const RECORD = getRecord(list, id);
  return { insurancePlans: [...RECORD.insurancePlans, plan] };
}

function _update(payload) {
  const { list, id, plan } = payload;
  const RECORD = getRecord(list, id);
  RECORD.insurancePlans = immutableUpdateListItems(RECORD.insurancePlans, plan);
  return RECORD;
}

const actions = {
  async create({ dispatch, rootState }, payload) {
    const { currentId, list } = rootState.insurancePayors;
    dispatch('setLoader', true, { root: true });

    try {
      const {
        data: { id }
      } = await Axios.post(`/insuranceplans`, payload);
      const data = _add({ list, id: currentId, plan: { ...payload, id } });
      return dispatch('insurancePayors/update', { id: currentId, data }, { root: true });
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async update({ dispatch, rootState }, payload) {
    const { currentId, list } = rootState.insurancePayors;
    const { id, data } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch(`/insuranceplans/${id}`, data);
      const INSURANCE_PAYOR = _update({ list, id: currentId, plan: payload });
      dispatch('insurancePayors/updateListItem', { id: currentId, data: INSURANCE_PAYOR }, { root: true });
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getData({ dispatch }) {
    dispatch('setLoader', true, { root: true });
    dispatch('setRequestGroup', true, { root: true });

    return Promise.all([
      dispatch('insurancePayors/getList', null, { root: true }),
      dispatch('states/getList', null, { root: true }),
      dispatch('departments/getData', null, { root: true })
    ])
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setRequestGroup', false, { root: true });
        dispatch('setLoader', false, { root: true });
      });
  }
};

export default {
  namespaced: true,
  actions
};
