/* eslint-disable no-undef */
import { get, isEqual as _isEqual } from 'lodash';

function hasChanges(original = {}, current = {}) {
  const CHANGES = getChanges(original, current);
  return Object.keys(CHANGES).length > 0;
}

function isEqual(original, current) {
  if (current instanceof Object || typeof current === 'object') {
    return _isEqual(original, current);
  }
  return original == current;
}

function sanitizeRow(data) {
  const KEYS = ['originalIndex', 'vgt_id'];
  const DATA = { ...data };
  for (const key of KEYS) {
    delete DATA[key];
  }
  return DATA;
}

function getNewObject(object) {
  return JSON.parse(JSON.stringify(object));
}

function getChanges(original, current) {
  return Object.keys(original).reduce((acc, key) => {
    if (!isEqual(current[key], original[key])) acc[key] = current[key];
    return acc;
  }, {});
}

function immutableUpdateListItems(list, payload) {
  const { id, data } = payload;
  const INDEX = list.findIndex((record) => record.id == id);
  const RECORD = { ...getNewObject(list[INDEX]), ...getNewObject(data) };
  return [...list.slice(0, INDEX), RECORD, ...list.slice(INDEX + 1)];
}

function getRecord(list, id, prop) {
  const PROP = prop || 'id';
  const RECORD = list.find((record) => record[PROP] == id);
  return { ...RECORD };
}

function handleError(error) {
  console.error(error);
  return Promise.reject(error);
}

function _sortNested(prop) {
  return (a, b) => {
    return String(get(a, prop)).localeCompare(String(get(b, prop)));
  };
}

function _sortPlain() {
  return (a, b) => {
    return String(a).localeCompare(String(b));
  };
}

function _handleSort(prop) {
  // If plain text array is provided
  if (!prop) return _sortPlain();
  // If a Collection is provided and nested prop
  if (prop.includes('.')) return _sortNested(prop);
  // If a Collection is provided and a one level prop
  return (a, b) => {
    return String(a[prop]).localeCompare(String(b[prop]));
  };
}

function sort(list, prop) {
  return [...list].sort(_handleSort(prop));
}

function getRandomId() {
  return Math.floor(Math.random() * 1000) + 1;
}

function handleWarningModal() {
  cy.get('[data-test="warning-modal"]').should('be.visible');
  cy.get('[data-test="warning-modal"]')
    .find('[data-test="confirm"]')
    .click({ force: true });

  cy.wait(3000);
}

function isTempRecord(record) {
  return String(record.id).includes('temp-');
}

async function base64ToBlob(image) {
  const FILE = await fetch(image);
  return FILE.blob();
}

function toFormData(object) {
  return Object.keys(object).reduce((acc, key) => {
    acc.append(key, object[key]);
    return acc;
  }, new FormData());
}

export {
  immutableUpdateListItems,
  handleWarningModal,
  toFormData,
  base64ToBlob,
  getNewObject,
  isTempRecord,
  sanitizeRow,
  handleError,
  getRandomId,
  hasChanges,
  getChanges,
  getRecord,
  isEqual,
  sort
};
