import { base64ToBlob, getNewObject, handleError, immutableUpdateListItems, sort, toFormData } from '@/utils';

import Axios from '@/libs/axios';
import AXIOS from 'axios';

const state = {
  list: []
};

const actions = {
  async getList({ commit, getters, dispatch }) {
    if (getters.hasList) return;
    dispatch('setLoader', true, { root: true });

    try {
      const SCHOOLS = await Axios.get('/schools');
      commit(
        'setList',
        SCHOOLS.data.map((school) => {
          if (!school.name) {
            school.name = '[UPDATE NAME]';
          }
          return school;
        })
      );
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async create({ commit, dispatch }, payload) {
    dispatch('setLoader', true, { root: true });
    try {
      const {
        data: { id }
      } = await Axios.post(`/schools/`, payload);
      commit('add', { ...payload, id });
      return id;
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async update({ commit, dispatch }, payload) {
    const { id, data } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch(`/schools/${id}`, data);
      commit('updateListItem', payload);
      commit('clinicians/resetList', payload, { root: true });
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async uploadPhoto({ commit, dispatch }, payload) {
    dispatch('setLoader', true, { root: true });
    const {
      id,
      data: { image }
    } = payload;

    try {
      const BLOB = await base64ToBlob(image);
      const RESPONSE = await Axios.post(`schools/${id}/image`, { type: BLOB.type });
      const { url, fields } = RESPONSE.data;
      const DATA = toFormData(fields);
      DATA.append('file', BLOB);
      await AXIOS.post(url, DATA, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      });
      // Due to image not being retrieved yet temporarily update Vuex list with base64 image data
      payload.data.image = image;
      commit('updateListItem', payload);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async delete({ commit, dispatch }, payload) {
    const id = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.delete(`/schools/${id}`);
      commit('deleteListItem', id);
      commit('clinicians/resetList', payload, { root: true });
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  bulkCreate({ dispatch }, payload) {
    dispatch('setLoader', true, { root: true });
    dispatch('setRequestGroup', true, { root: true });

    const PAYLOAD = getNewObject(payload).map((record) => {
      if (record.id) delete record.id;
      return record;
    });

    return Promise.all(PAYLOAD.map((data) => dispatch('create', data)))
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setRequestGroup', false, { root: true });
        dispatch('setLoader', false, { root: true });
      });
  },
  bulkUpload({ dispatch }, payload) {
    dispatch('setLoader', true, { root: true });
    dispatch('setRequestGroup', true, { root: true });

    return Promise.all(payload.map((data) => dispatch('uploadPhoto', data)))
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setRequestGroup', false, { root: true });
        dispatch('setLoader', false, { root: true });
      });
  }
};

const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
  updateListItem(state, payload) {
    state.list = immutableUpdateListItems(state.list, payload);
  },
  add(state, payload) {
    state.list = [...state.list, payload];
  },
  deleteListItem(state, id) {
    state.list = state.list.filter((record) => record.id !== id);
  },
  resetList(state) {
    state.list = [];
  }
};

const getters = {
  hasList: (state) => {
    return state.list.length > 0;
  },
  list: (state) => {
    return sort(state.list, 'name');
  },
  enabledList: (state, getters) => {
    return getters.list.filter((record) => record.enabled);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
