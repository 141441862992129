import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import mixin from './libs/mixins/global';
import Auth from './libs/auth';
import Axios from './libs/axios';
import PortalVue from 'portal-vue';
import { name, version } from '../package';

import * as Sentry from '@sentry/vue';
// Vue-notification
import Notifications from 'vue-notification';

// Vue-good-table
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

// Vue-js-modal
import VModal from 'vue-js-modal/dist/index.nocss';
import 'vue-js-modal/dist/styles.css';

// Vue-multiselect
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
// Fontawesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import IdleVue from 'idle-vue';
//Launch Darkly
import ldPrototype from '@/plugins/ldClient';

Vue.component('Multiselect', Multiselect);

library.add(faInfoCircle);
library.add(faSearch);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VModal);
Vue.use(VueGoodTablePlugin);
Vue.use(Notifications);
Vue.use(PortalVue);

const eventEmitter = new Vue();
Vue.use(IdleVue, {
  eventEmitter,
  store,
  idleTime: 1800000,
  startAtIdle: false
});

let user = {
  anonymous: true,
  key: 'hardloop-configuration-frontend'
};
Vue.use(ldPrototype, {
  clientSideId: process.env.VUE_APP_LD_CLIENT_ID,
  user
});

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  tunnel: `${process.env.VUE_APP_API_BASE_URL}/bugs`,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ['localhost', /^\//]
    })
  ],
  environment: process.env.VUE_APP_STAGE || process.env.NODE_ENV,
  release: `${name}@${version}`,
  logErrors: process.env.NODE_ENV === 'development',
  tracesSampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE
});

Vue.mixin({ ...mixin });

Object.defineProperty(Vue.prototype, '$auth', { value: Auth });
Object.defineProperty(Vue.prototype, '$axios', { value: Axios });

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
