<template>
  <div id="siteLayout">
    <notifications group="app" />
    <Spinner v-show="isLoading" />
    <nav-header />
    <div class="main-container">
      <sidebar />
      <slot />
    </div>
  </div>
</template>
<script>
  import Spinner from '@/components/base/spinner';
  import NavHeader from './header';
  import Sidebar from './sidebar';

  import { mapState, mapActions } from 'vuex';
  export default {
    name: 'SiteLayout',
    title: 'Hardloop Configuration Site',
    components: {
      NavHeader,
      Spinner,
      Sidebar
    },
    onIdle() {
      this.handleInactivity();
    },
    mounted() {
      if (window.localStorage.getItem('wasLoggedOutByInactivity')) this.showInactivityModal();
    },
    data() {
      return {
        hide: false
      };
    },
    computed: {
      ...mapState(['isLoading'])
    },
    methods: {
      ...mapActions('user', ['logOut']),
      handleInactivity() {
        window.localStorage.setItem('wasLoggedOutByInactivity', true);
        this.logOut();
      }
    }
  };
</script>

<style scoped>
  .main-container {
    display: flex;
    flex-direction: row;
    flex: 1;
  }
  #siteLayout {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
</style>
