import { immutableUpdateListItems, handleError, sort } from '@/utils';

import Axios from '@/libs/axios';

const state = {
  list: []
};

const actions = {
  async getList({ commit, getters, dispatch }) {
    if (getters.hasList) return;
    dispatch('setLoader', true, { root: true });

    try {
      const SUFFIXES = await Axios.get('/suffixes');
      commit('setList', SUFFIXES.data);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async create({ commit, dispatch }, payload) {
    dispatch('setLoader', true, { root: true });

    try {
      const {
        data: { id }
      } = await Axios.post(`/suffixes/`, payload);
      commit('add', { ...payload, id });
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async update({ commit, dispatch }, payload) {
    const { id, data } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch(`/suffixes/${id}`, data);
      commit('updateListItem', payload);
      commit('clinicians/resetList', payload, { root: true });
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async delete({ commit, dispatch }, payload) {
    const id = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.delete(`/suffixes/${id}`);
      commit('deleteListItem', id);
      commit('clinicians/resetList', payload, { root: true });
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  }
};

const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
  updateListItem(state, payload) {
    state.list = immutableUpdateListItems(state.list, payload);
  },
  add(state, payload) {
    state.list = [...state.list, payload];
  },
  deleteListItem(state, id) {
    state.list = state.list.filter((record) => record.id !== id);
  }
};

const getters = {
  hasList: (state) => {
    return state.list.length > 0;
  },
  enabledList: (state, getters) => {
    return getters.list.filter((record) => record.enabled);
  },
  list: (state) => {
    return sort(state.list, 'name');
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
