export const FlagDefaultMap = new Map();

export const AppointmentTypesEditable = 'Views-AppointmentTypes-Editable';
export const ClinicianDetailLinkVisible = 'Views-ClinicianDetails-Link-Visible';
export const CancelReasonsEditable = 'Views-CancelReasons-Editable';

// Add Feature Flag's default for flags used in dom or non false defaults
FlagDefaultMap.set(AppointmentTypesEditable, false);
FlagDefaultMap.set(ClinicianDetailLinkVisible, true);
FlagDefaultMap.set(CancelReasonsEditable, false);
