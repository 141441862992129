import { handleError } from '@/utils';
import Axios from '@/libs/axios';

const state = {
  data: {}
};

const actions = {
  async update({ commit, dispatch }, payload) {
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/settings', payload);
      commit('setData', payload);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getData({ commit, getters, dispatch }) {
    if (getters.hasData) return;
    dispatch('setLoader', true, { root: true });

    try {
      const SETTINGS = await Axios.get('/settings');
      commit('setData', SETTINGS.data);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  }
};

const mutations = {
  setData(state, payload) {
    state.data = { ...state.data, ...payload };
  }
};

const getters = {
  hasData: (state) => {
    return Object.keys(state.data).length > 0;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
