import { immutableUpdateListItems, handleError, isTempRecord, getNewObject } from '@/utils';
import Axios from '@/libs/axios';

const state = {
  list: []
};

const actions = {
  getData({ dispatch }) {
    dispatch('setLoader', true, { root: true });
    dispatch('setRequestGroup', true, { root: true });
    return Promise.all([dispatch('getList'), dispatch('states/getList', null, { root: true })])
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setRequestGroup', false, { root: true });
        dispatch('setLoader', false, { root: true });
      });
  },
  async getList({ commit, getters, dispatch }) {
    if (getters.hasList) return;
    dispatch('setLoader', true, { root: true });

    try {
      const RECORDS = await Axios.get('/testimonials');
      commit('setList', RECORDS.data);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async create({ commit, dispatch }, payload) {
    dispatch('setLoader', true, { root: true });

    try {
      const {
        data: { id }
      } = await Axios.post(`/testimonials/`, payload);
      commit('add', { ...payload, id });

      return id;
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async update({ dispatch }, payload) {
    const { id, data } = payload;
    dispatch('setLoader', true, { root: true });
    try {
      await Axios.patch(`/testimonials/${id}`, data);
      /* NOTE: Currently there is no need to call the `updateListItem` mutation since Testimonials are related to a clinicians
      Not a global entity like the other modules */
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async delete({ commit, dispatch }, payload) {
    const id = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.delete(`/testimonials/${id}`);
      commit('deleteListItem', id);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  bulkDelete({ dispatch }, payload) {
    dispatch('setLoader', true, { root: true });
    dispatch('setRequestGroup', true, { root: true });

    return Promise.all(payload.map((id) => dispatch('delete', id)))
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setRequestGroup', false, { root: true });
        dispatch('setLoader', false, { root: true });
      });
  },
  bulkUpdate({ dispatch }, payload) {
    dispatch('setLoader', true, { root: true });
    dispatch('setRequestGroup', true, { root: true });

    return Promise.all(payload.map((data) => dispatch('update', data)))
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setRequestGroup', false, { root: true });
        dispatch('setLoader', false, { root: true });
      });
  },
  bulkCreate({ dispatch }, payload) {
    dispatch('setLoader', true, { root: true });
    dispatch('setRequestGroup', true, { root: true });

    const PAYLOAD = getNewObject(payload).map((record) => {
      if (record.id) delete record.id;
      return record;
    });

    return Promise.all(PAYLOAD.map((data) => dispatch('create', data)))
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setRequestGroup', false, { root: true });
        dispatch('setLoader', false, { root: true });
      });
  },
  bulkHandle({ dispatch }, payload) {
    dispatch('setLoader', true, { root: true });
    dispatch('setRequestGroup', true, { root: true });

    return Promise.all(payload.map((data) => dispatch('resolveUpdate', data)))
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setRequestGroup', false, { root: true });
        dispatch('setLoader', false, { root: true });
      });
  },
  resolveUpdate({ dispatch }, payload) {
    if (isTempRecord(payload)) return dispatch('create', payload.data);
    return dispatch('update', payload);
  }
};

const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
  updateListItem(state, payload) {
    state.list = immutableUpdateListItems(state.list, payload);
  },
  add(state, payload) {
    state.list = [...state.list, payload];
  },
  deleteListItem(state, id) {
    state.list = state.list.filter((record) => record.id !== id);
  }
};

const getters = {
  hasList: (state) => {
    return state.list.length > 0;
  },
  list: (state) => {
    return state.list;
  },
  enabledList: (state, getters) => {
    return getters.list.filter((record) => record.enabled);
  }
};

export const model = { name: '', city: '', quote: '', state: {} };

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
