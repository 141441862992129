<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<style>
  @import 'assets/css/main.css';
</style>

<script>
  import { mapState } from 'vuex';
  import SiteLayout from './layouts/site';
  import PublicLayout from './layouts/public.vue';

  export default {
    name: 'App',
    components: {
      SiteLayout,
      PublicLayout
    },
    data() {
      return {
        layout: ''
      };
    },
    computed: {
      ...mapState(['publicRoutes'])
    },
    watch: {
      $route: {
        handler(to) {
          this.layout = this.publicRoutes.includes(to.name) || !to.name ? 'PublicLayout' : 'SiteLayout';
        },
        immediate: true
      }
    }
  };
</script>

<style scoped>
  #app {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
</style>
