import { handleError, immutableUpdateListItems, sort } from '@/utils';
import Axios from '@/libs/axios';

const state = {
  list: [],
  onlineCancelCancelReasonId: '',
  lateCancelCancelReasonId: '',
  onlineRescheduleCancelReasonId: '',
  creditCardCancelReasonId: '',
  clinicianCancelReasonId: ''
};

const actions = {
  setOnlineCancelCancelReasonId({ commit }, payload) {
    commit('setOnlineCancelCancelReasonId', payload);
  },
  setLateCancelCancelReasonId({ commit }, payload) {
    commit('setLateCancelCancelReasonId', payload);
  },
  setOnlineRescheduleCancelReasonId({ commit }, payload) {
    commit('setOnlineRescheduleCancelReasonId', payload);
  },
  setCreditCardCancelReasonId({ commit }, payload) {
    commit('setCreditCardCancelReasonId', payload);
  },
  setClinicianCancelReasonId({ commit }, payload) {
    commit('setClinicianCancelReasonId', payload);
  },
  setList({ commit }, payload) {
    commit('setList', payload);
  },
  getListData({ getters, dispatch }) {
    if (getters.hasData) return;
    dispatch('setLoader', true, { root: true });
    dispatch('setRequestGroup', true, { root: true });

    return Promise.all([
      dispatch('getList'),
      dispatch('getOnlineCancelCancelReasonId'),
      dispatch('getLateCancelCancelReasonId'),
      dispatch('getOnlineRescheduleCancelReasonId'),
      dispatch('getCreditCardCancelReasonId'),
      dispatch('getClinicianCancelReasonId')
    ])
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setRequestGroup', false, { root: true });
        dispatch('setLoader', false, { root: true });
      });
  },
  async getList({ commit, getters, dispatch }) {
    if (getters.hasData) return;
    dispatch('setLoader', true, { root: true });

    try {
      const CANCEL_REASONS = await Axios.get('/appointmentcancelreasons');

      commit('setList', CANCEL_REASONS.data);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getOnlineCancelCancelReasonId({ commit, getters, dispatch }) {
    if (getters.hasOnlineCancelCancelReasonId) return;
    dispatch('setLoader', true, { root: true });

    try {
      const CANCEL_REASON = await Axios.get('/appointmentcancelreasons/online-cancel');
      commit('setOnlineCancelCancelReasonId', CANCEL_REASON.data.cancelReasonId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getLateCancelCancelReasonId({ commit, getters, dispatch }) {
    if (getters.hasLateCancelCancelReasonId) return;
    dispatch('setLoader', true, { root: true });

    try {
      const CANCEL_REASON = await Axios.get('/appointmentcancelreasons/late-cancel');
      commit('setLateCancelCancelReasonId', CANCEL_REASON.data.cancelReasonId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getOnlineRescheduleCancelReasonId({ commit, getters, dispatch }) {
    if (getters.hasOnlineRescheduleCancelReasonId) return;
    dispatch('setLoader', true, { root: true });

    try {
      const CANCEL_REASON = await Axios.get('/appointmentcancelreasons/online-reschedule');
      commit('setOnlineRescheduleCancelReasonId', CANCEL_REASON.data.cancelReasonId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getCreditCardCancelReasonId({ commit, getters, dispatch }) {
    if (getters.hasCreditCardCancelReasonId) return;
    dispatch('setLoader', true, { root: true });

    try {
      const CANCEL_REASON = await Axios.get('/appointmentcancelreasons/creditcard');
      commit('setCreditCardCancelReasonId', CANCEL_REASON.data.cancelReasonId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getClinicianCancelReasonId({ commit, getters, dispatch }) {
    if (getters.hasClinicianCancelReasonId) return;
    dispatch('setLoader', true, { root: true });

    try {
      const CANCEL_REASON = await Axios.get('/appointmentcancelreasons/clinician-cancel');
      commit('setClinicianCancelReasonId', CANCEL_REASON.data.cancelReasonId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async update({ commit, dispatch }, payload) {
    const { id, data } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch(`/appointmentcancelreasons/${id}`, data);
      commit('updateListItem', payload);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateOnlineCancelCancelReasonId({ commit, dispatch }, payload) {
    const { cancelReasonId } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/appointmentcancelreasons/online-cancel', payload);
      commit('setOnlineCancelCancelReasonId', cancelReasonId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateLateCancelCancelReasonId({ commit, dispatch }, payload) {
    const { cancelReasonId } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/appointmentcancelreasons/late-cancel', payload);
      commit('setLateCancelCancelReasonId', cancelReasonId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateOnlineRescheduleCancelReasonId({ commit, dispatch }, payload) {
    const { cancelReasonId } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/appointmentcancelreasons/online-reschedule', payload);
      commit('setOnlineRescheduleCancelReasonId', cancelReasonId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateCreditCardCancelReasonId({ commit, dispatch }, payload) {
    const { cancelReasonId } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/appointmentcancelreasons/creditcard', payload);
      commit('setCreditCardCancelReasonId', cancelReasonId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateClinicianCancelReasonId({ commit, dispatch }, payload) {
    const { cancelReasonId } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/appointmentcancelreasons/clinician-cancel', payload);
      commit('setClinicianCancelReasonId', cancelReasonId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  }
};

const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
  setOnlineCancelCancelReasonId(state, payload) {
    const data = payload || 'fake-cancel-id';
    state.onlineCancelCancelReasonId = data;
  },
  setLateCancelCancelReasonId(state, payload) {
    const data = payload || 'fake-cancel-id';
    state.lateCancelCancelReasonId = data;
  },
  setOnlineRescheduleCancelReasonId(state, payload) {
    const data = payload || 'fake-cancel-id';
    state.onlineRescheduleCancelReasonId = data;
  },
  setCreditCardCancelReasonId(state, payload) {
    const data = payload || 'fake-cancel-id';
    state.creditCardCancelReasonId = data;
  },
  setClinicianCancelReasonId(state, payload) {
    const data = payload || 'fake-cancel-id';
    state.clinicianCancelReasonId = data;
  },
  updateListItem(state, payload) {
    state.list = immutableUpdateListItems(state.list, payload);
  }
};

const getters = {
  hasData: (state, getters) => {
    return (
      getters.hasOnlineCancelCancelReasonId &&
      getters.haslateCancelCancelReasonId &&
      getters.hasOnlineRescheduleCancelReasonId &&
      getters.hasCreditCardCancelReasonId &&
      getters.hasClinicianCancelReasonId &&
      getters.hasList
    );
  },
  hasList: (state) => {
    return state.list.length > 0;
  },
  hasOnlineCancelCancelReasonId: (state) => {
    return !!state.onlineCancelCancelReasonId;
  },
  hasLateCancelCancelReasonId: (state) => {
    return !!state.lateCancelCancelReasonId;
  },
  hasOnlineRescheduleCancelReasonId: (state) => {
    return !!state.onlineRescheduleCancelReasonId;
  },
  hasCreditCardCancelReasonId: (state) => {
    return !!state.creditCardCancelReasonId;
  },
  hasClinicianCancelReasonId: (state) => {
    return !!state.clinicianCancelReasonId;
  },
  list: (state) => {
    return sort(state.list, 'name');
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
