import { base64ToBlob, handleError, immutableUpdateListItems, sort, toFormData } from '@/utils';
import Axios, { handleSuccess } from '@/libs/axios';
import AXIOS from 'axios';

function makeRecordUnsortable(record) {
  if (record.sortOrder !== undefined) delete record.sortOrder;
}

function setOrder(orderIds, list) {
  // Mutate the SortOrder property of every Clinician matching its id
  // Remove undefined and sort asc based on sortOrder
  return list.map((record) => {
    // Update sortOrder on record
    const RECORD = { ...record };

    if (!orderIds.includes(RECORD.id)) {
      makeRecordUnsortable(RECORD);
    } else {
      // Set the index found + 1
      const INDEX = orderIds.findIndex((id) => id == RECORD.id);
      RECORD.sortOrder = INDEX + 1;
    }

    return RECORD;
  });
}

const state = {
  currentSortType: 'asc',
  list: [],
  LGMDGroupId: ''
};

const actions = {
  async getLGMDDepartmentId({ commit }) {
    const PROVIDER_GROUPS = await Axios.get('/providergroups');
    const providerGroup = PROVIDER_GROUPS.data.find((providerGroup) => providerGroup.athenaId === '1');
    commit('setLGMDGroupId', providerGroup.id);
  },
  async getList({ state, commit, getters, dispatch }) {
    if (getters.hasList) return;
    dispatch('setLoader', true, { root: true });

    try {
      if (!state.LGMDGroupId) {
        await dispatch('getLGMDDepartmentId');
      }

      const RECORDS = await Axios.get(`/providers/?limit=2000&offset=0&providerGroupId=${state.LGMDGroupId}`);
      commit('setList', RECORDS.data);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  getListData({ dispatch }) {
    dispatch('setLoader', true, { root: true });
    dispatch('setRequestGroup', true, { root: true });

    // Fetch all the data needed for the Clinician Details Modal to properly work (languages, insurancePayors, etc)
    return Promise.all([dispatch('getList'), dispatch('suffixes/getList', null, { root: true })])
      .then((data) => {
        // Returned the fetched clinician (as it is the first ajax call made it is return in the 0 index)
        return data[0];
      })
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setRequestGroup', false, { root: true });
        dispatch('setLoader', false, { root: true });
      });
  },
  getData({ dispatch }, id) {
    dispatch('setLoader', true, { root: true });
    dispatch('setRequestGroup', true, { root: true });

    // Fetch all the data needed for the Clinician Details Modal to properly work (languages, insurancePayors, etc)
    return Promise.all([
      dispatch('getRecord', id),
      dispatch('insurancePayors/getList', null, { root: true }),
      dispatch('specialties/getList', null, { root: true }),
      dispatch('suffixes/getList', null, { root: true }),
      dispatch('languages/getList', null, { root: true }),
      dispatch('states/getList', null, { root: true }),
      dispatch('clinicianTypes/getList', null, { root: true }),
      dispatch('titles/getList', null, { root: true }),
      dispatch('schools/getList', null, { root: true })
    ])
      .then((data) => {
        // Returned the fetched clinician (as it is the first ajax call made it is return in the 0 index)
        return data[0];
      })
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setRequestGroup', false, { root: true });
        dispatch('setLoader', false, { root: true });
      });
  },
  async getRecord({ dispatch }, id) {
    dispatch('setLoader', true, { root: true });

    return Axios.get(`/providers/${id}`)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setLoader', false, { root: true });
      });
  },
  async uploadPhoto({ dispatch }, payload) {
    dispatch('setLoader', true, { root: true });
    const { id, image } = payload;

    try {
      const BLOB = await base64ToBlob(image);
      const RESPONSE = await Axios.post(`providers/${id}/image`, { type: BLOB.type });
      const { url, fields } = RESPONSE.data;
      const DATA = toFormData(fields);
      DATA.append('file', BLOB);
      const response = await AXIOS.post(url, DATA, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      });
      return handleSuccess(response);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async update({ commit, dispatch }, payload) {
    const { id, data } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch(`/providers/${id}`, data);
      commit('updateListItem', payload);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  setList({ commit }, payload) {
    commit('setList', payload);
  },
  async updateOrder({ commit, dispatch }, payload) {
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.post('/providers/sort', payload);
      commit('setOrder', payload);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  }
};

const mutations = {
  setCurrentSortType(state, payload) {
    state.currentSortType = payload;
  },
  setLGMDGroupId: (state, payload) => {
    state.LGMDGroupId = payload;
  },
  setList(state, payload) {
    state.list = payload;
  },
  resetList(state) {
    state.list = [];
  },
  updateListItem(state, payload) {
    state.list = immutableUpdateListItems(state.list, payload);
  },
  setOrder(state, payload) {
    state.list = setOrder(payload, state.list);
  }
};

const getters = {
  currentSortType: (state) => {
    return state.currentSortType;
  },
  hasList: (state) => {
    return state.list.length > 0;
  },
  list: (state) => {
    return sort(state.list, 'name');
  },
  orderedList: (state) => {
    return state.list.filter((record) => record.sortOrder !== undefined).sort((a, b) => a.sortOrder - b.sortOrder);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};

export { setOrder };
