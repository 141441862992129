import { immutableUpdateListItems, handleError, sort } from '@/utils';

import Axios from '@/libs/axios';

const state = {
  list: [],
  groupingList: ['Medical Professional', 'Therapist', 'Nutritionist'],
  matchSheetGroupList: ['Psychiatrist', 'Psychiatric Nurse Practitioner', 'Therapist', 'Psychologist', 'Nutritionist']
};

const actions = {
  async getList({ commit, getters, dispatch }) {
    if (getters.hasList) return;
    dispatch('setLoader', true, { root: true });

    try {
      const RESPONSE = await Axios.get('/providertypes');
      commit('setList', RESPONSE.data);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async update({ commit, dispatch }, payload) {
    const { id, data } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch(`/providertypes/${id}`, data);
      commit('updateListItem', payload);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  }
};

const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
  updateListItem(state, payload) {
    state.list = immutableUpdateListItems(state.list, payload);
  }
};

const getters = {
  hasList: (state) => {
    return state.list.length > 0;
  },
  list: (state) => {
    return sort(state.list, 'name');
  },
  groupingList: (state) => {
    return sort(state.groupingList);
  },
  matchSheetGroupList: (state) => {
    return state.matchSheetGroupList;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
