import { handleError, sort } from '@/utils';
import Axios from '@/libs/axios';

const state = {
  creditCardDepartmentIds: [],
  list: [],
  selectedProviderGroupId: '',
  providerGroups: []
};

const actions = {
  async getViewData({ getters, dispatch }) {
    if (getters.hasData) return;

    dispatch('setLoader', true, { root: true });
    dispatch('setRequestGroup', true, { root: true });

    return Promise.all([dispatch('states/getList', null, { root: true }), dispatch('getData')])
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setRequestGroup', false, { root: true });
        dispatch('setLoader', false, { root: true });
      });
  },
  async updateCreditCardId({ commit, dispatch, state }, payload) {
    if (!payload) return;
    const creditCardDepartmentIds = state.creditCardDepartmentIds;
    const index = creditCardDepartmentIds.findIndex((ccid) => ccid.providerGroupId === state.selectedProviderGroupId);
    if (index >= 0) {
      creditCardDepartmentIds[index].departmentId = payload.id;
    } else {
      creditCardDepartmentIds.push({ departmentId: payload.id, providerGroupId: state.selectedProviderGroupId });
    }

    dispatch('setLoader', true, { root: true });
    try {
      await Axios.patch('/departments/creditcard', { providerGroupDepartmentIds: creditCardDepartmentIds });
      commit('setCreditCardDepartmentIds', creditCardDepartmentIds);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateProviderGroup({ commit }, payload) {
    commit('selectProviderGroup', payload.id);
  },
  async getData({ commit, getters, dispatch }) {
    if (getters.hasList) return;
    dispatch('setLoader', true, { root: true });

    try {
      const CREDIT_CARD = await Axios.get('/departments/creditcard');
      const DEPARTMENTS = await Axios.get('/departments');
      commit('setCreditCardDepartmentIds', CREDIT_CARD.data.providerGroupDepartmentIds);
      commit('setList', DEPARTMENTS.data);
      await dispatch('getProviderGroupData');
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getProviderGroupData({ commit }) {
    try {
      const PROVIDER_GROUPS = await Axios.get('/providergroups');
      commit('setProviderGroups', PROVIDER_GROUPS.data);
    } catch (error) {
      return handleError(error);
    }
  }
};

const mutations = {
  setCreditCardDepartmentIds(state, payload) {
    state.creditCardDepartmentIds = payload;
  },
  setList(state, payload) {
    state.list = payload;
  },
  setProviderGroups(state, payload) {
    state.providerGroups = payload;
    state.selectedProviderGroupId = payload[0].id;
  },
  selectProviderGroup(state, payload) {
    state.selectedProviderGroupId = payload;
  }
};

const getters = {
  hasProviderGroups: (state) => {
    return state.providerGroups.length > 0;
  },
  hasList: (state) => {
    return state.list.length > 0;
  },
  hasData: (state, getters, rootState, rootGetters) => {
    return rootGetters['states/hasList'] && getters.hasList;
  },
  list: (state) => {
    return sort(state.list, 'name');
  },
  providerGroups: (state) => {
    return sort(state.providerGroups, 'name');
  },
  selectedProviderGroupId: (state) => {
    return state.selectedProviderGroupId;
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
