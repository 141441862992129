import { immutableUpdateListItems, handleError, sort } from '@/utils';
import Axios from '@/libs/axios';

const state = {
  bookingId: '',
  followupShortId: '',
  followupLongId: '',
  bookingNewTherapistId: '',
  bookingFollowTherapistId: '',
  bookingNewNutritionistId: '',
  bookingFollowNutritionistId: '',
  creditCardId: '',
  list: []
};

const actions = {
  async updateCreditCardId({ commit, dispatch }, payload) {
    const { appointmentTypeId } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/appointmenttypes/creditcard', payload);
      commit('setCreditCardId', appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateBookingId({ commit, dispatch }, payload) {
    const { appointmentTypeId } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/appointmenttypes/booking?type=booking', payload);
      commit('setBookingId', appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateFollowupShortId({ commit, dispatch }, payload) {
    const { appointmentTypeId } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/appointmenttypes/booking?type=followup-short', payload);
      commit('setFollowupShortId', appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateFollowupLongId({ commit, dispatch }, payload) {
    const { appointmentTypeId } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/appointmenttypes/booking?type=followup-long', payload);
      commit('setFollowupLongId', appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateBookingNewTherapistId({ commit, dispatch }, payload) {
    const { appointmentTypeId } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/appointmenttypes/booking?type=therapistbooking', payload);
      commit('setBookingNewTherapistId', appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateBookingFollowTherapistId({ commit, dispatch }, payload) {
    const { appointmentTypeId } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/appointmenttypes/booking?type=therapistfollowup', payload);
      commit('setBookingFollowTherapistId', appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateBookingNewNutritionistId({ commit, dispatch }, payload) {
    const { appointmentTypeId } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/appointmenttypes/booking?type=nutritionist-booking', payload);
      commit('setBookingNewNutritionistId', appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async updateBookingFollowNutritionistId({ commit, dispatch }, payload) {
    const { appointmentTypeId } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch('/appointmenttypes/booking?type=nutritionist-followup', payload);
      commit('setBookingFollowNutritionistId', appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async update({ commit, dispatch }, payload) {
    const { id, data } = payload;
    dispatch('setLoader', true, { root: true });

    try {
      await Axios.patch(`/appointmenttypes/${id}`, data);
      commit('updateListItem', payload);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  getListData({ getters, dispatch }) {
    if (getters.hasData) return;
    dispatch('setLoader', true, { root: true });
    dispatch('setRequestGroup', true, { root: true });

    return Promise.all([
      dispatch('getList'),
      dispatch('getBookingId'),
      dispatch('getFollowupShortId'),
      dispatch('getFollowupLongId'),
      dispatch('getCreditCardId'),
      dispatch('getBookingNewTherapistId'),
      dispatch('getBookingFollowTherapistId'),
      dispatch('getBookingNewNutritionistId'),
      dispatch('getBookingFollowNutritionistId')
    ])
      .catch((error) => {
        return handleError(error);
      })
      .finally(() => {
        dispatch('setRequestGroup', false, { root: true });
        dispatch('setLoader', false, { root: true });
      });
  },
  async getList({ commit, getters, dispatch }) {
    if (getters.hasData) return;
    dispatch('setLoader', true, { root: true });

    try {
      const APPOINTMENT_TYPES = await Axios.get('/appointmenttypes/');
      commit('setList', APPOINTMENT_TYPES.data);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getBookingId({ commit, getters, dispatch }) {
    if (getters.hasBookingId) return;
    dispatch('setLoader', true, { root: true });

    try {
      const BOOKING = await Axios.get('/appointmenttypes/booking?type=booking');
      commit('setBookingId', BOOKING.data.appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getFollowupShortId({ commit, getters, dispatch }) {
    if (getters.hasFollowupShortId) return;
    dispatch('setLoader', true, { root: true });

    try {
      const BOOKING = await Axios.get('/appointmenttypes/booking?type=followup-short');
      commit('setFollowupShortId', BOOKING.data.appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getFollowupLongId({ commit, getters, dispatch }) {
    if (getters.hasFollowupLongId) return;
    dispatch('setLoader', true, { root: true });

    try {
      const BOOKING = await Axios.get('/appointmenttypes/booking?type=followup-long');
      commit('setFollowupLongId', BOOKING.data.appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getBookingNewTherapistId({ commit, getters, dispatch }) {
    if (getters.hasBookingNewTherapistId) return;
    dispatch('setLoader', true, { root: true });

    try {
      const BOOKING_NEW_THERAPIST = await Axios.get('/appointmenttypes/booking?type=therapistbooking');
      commit('setBookingNewTherapistId', BOOKING_NEW_THERAPIST.data.appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getBookingFollowTherapistId({ commit, getters, dispatch }) {
    if (getters.hasBookingFollowTherapistId) return;
    dispatch('setLoader', true, { root: true });

    try {
      const BOOKING_FOLLOW_THERAPIST = await Axios.get('/appointmenttypes/booking?type=therapistfollowup');
      commit('setBookingFollowTherapistId', BOOKING_FOLLOW_THERAPIST.data.appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getBookingNewNutritionistId({ commit, getters, dispatch }) {
    if (getters.hasBookingNewNutritionistId) return;
    dispatch('setLoader', true, { root: true });

    try {
      const BOOKING_NEW_THERAPIST = await Axios.get('/appointmenttypes/booking?type=nutritionist-booking');
      commit('setBookingNewNutritionistId', BOOKING_NEW_THERAPIST.data.appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getBookingFollowNutritionistId({ commit, getters, dispatch }) {
    if (getters.hasBookingFollowNutritionistId) return;
    dispatch('setLoader', true, { root: true });

    try {
      const BOOKING_FOLLOW_THERAPIST = await Axios.get('/appointmenttypes/booking?type=nutritionist-followup');
      commit('setBookingFollowNutritionistId', BOOKING_FOLLOW_THERAPIST.data.appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  async getCreditCardId({ commit, getters, dispatch }) {
    if (getters.hasCreditCardId) return;
    dispatch('setLoader', true, { root: true });

    try {
      const CREDIT_CARD = await Axios.get('/appointmenttypes/creditcard');
      commit('setCreditCardId', CREDIT_CARD.data.appointmentTypeId);
    } catch (error) {
      return handleError(error);
    } finally {
      dispatch('setLoader', false, { root: true });
    }
  },
  setBookingId({ commit }, payload) {
    commit('setBookingId', payload);
  },
  setFollowupShortId({ commit }, payload) {
    commit('setFollowupShortId', payload);
  },
  setFollowupLongId({ commit }, payload) {
    commit('setFollowupLongId', payload);
  },
  setBookingNewTherapistId({ commit }, payload) {
    commit('setBookingNewTherapistId', payload);
  },
  setBookingFollowTherapistId({ commit }, payload) {
    commit('setBookingFollowTherapistId', payload);
  },
  setBookingNewNutritionistId({ commit }, payload) {
    commit('setBookingNewNutritionistId', payload);
  },
  setBookingFollowNutritionistId({ commit }, payload) {
    commit('setBookingFollowNutritionistId', payload);
  },
  setCreditCardId({ commit }, payload = 'fake-credit-card-id') {
    commit('setCreditCardId', payload);
  },
  setList({ commit }, payload) {
    commit('setList', payload);
  }
};

const mutations = {
  setList(state, payload) {
    state.list = payload;
  },
  setBookingId(state, payload) {
    const data = payload || 'fake-booking-id';
    state.bookingId = data;
  },
  setFollowupShortId(state, payload) {
    const data = payload || 'fake-followup-id';
    state.followupShortId = data;
  },
  setFollowupLongId(state, payload) {
    const data = payload || 'fake-followup-id';
    state.followupLongId = data;
  },
  setBookingNewTherapistId(state, payload) {
    const data = payload || 'fake-booking-id';
    state.bookingNewTherapistId = data;
  },
  setBookingFollowTherapistId(state, payload) {
    const data = payload || 'fake-booking-id';
    state.bookingFollowTherapistId = data;
  },
  setBookingNewNutritionistId(state, payload) {
    const data = payload || 'fake-booking-id';
    state.bookingNewNutritionistId = data;
  },
  setBookingFollowNutritionistId(state, payload) {
    const data = payload || 'fake-booking-id';
    state.bookingFollowNutritionistId = data;
  },
  setCreditCardId(state, payload) {
    const data = payload || 'fake-booking-id';
    state.creditCardId = data;
  },
  updateListItem(state, payload) {
    state.list = immutableUpdateListItems(state.list, payload);
  }
};

const getters = {
  hasData: (state, getters) => {
    return (
      getters.hasBookingId &&
      getters.hasFollowupShortId &&
      getters.hasFollowupLongId &&
      getters.hasBookingNewTherapistId &&
      getters.hasBookingFollowTherapistId &&
      getters.hasBookingNewNutritionistId &&
      getters.hasBookingFollowNutritionistId &&
      getters.hasCreditCardId &&
      getters.hasList
    );
  },
  hasList: (state) => {
    return state.list.length > 0;
  },
  hasCreditCardId: (state) => {
    return !!state.creditCardId;
  },
  hasBookingId: (state) => {
    return !!state.bookingId;
  },
  hasFollowupShortId: (state) => {
    return !!state.followupShortId;
  },
  hasFollowupLongId: (state) => {
    return !!state.followupLongId;
  },
  hasBookingNewTherapistId: (state) => {
    return !!state.bookingNewTherapistId;
  },
  hasBookingFollowTherapistId: (state) => {
    return !!state.bookingFollowTherapistId;
  },
  hasBookingNewNutritionistId: (state) => {
    return !!state.bookingNewNutritionistId;
  },
  hasBookingFollowNutritionistId: (state) => {
    return !!state.bookingFollowNutritionistId;
  },
  list: (state) => {
    return sort(state.list, 'name');
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
